import React, {useEffect, useState} from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  Grid, Typography,
} from '@material-ui/core';
import {connect} from 'react-redux';

import {usergroupActions, organizationActions} from '_state/actions';
import ClassroomSidebar from "./Components/ClassroomSidebar";
import {FormattedMessage} from 'react-intl';
import EmailInvite from "../../../Components/EmailInvite";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

import { makeStyles } from '@material-ui/core/styles';
import CapeeshStyledModal from "../../../Components/CapeeshStyledModal";
import Button from "@material-ui/core/Button";
import {CapeeshColors} from "../../../assets/ColorPalette";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import TableContainer from "@mui/material/TableContainer";

const useStyles = makeStyles(theme => ({
    root: {
      marginLeft: 'calc(15% + 25px)',
      marginRight:'25px'
    },

    tableHeaderCell: {
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      fontFamily: 'Rubik',
      textAlign: 'left',
    },

    tableHeaderCellSmallText: {
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontFamily: 'Rubik',
      textAlign: 'left',
    },


    tableRowCellName: {
      color: CapeeshColors.Pink,
      fontSize: '14px',
      fontStyle: 'normal',
      fontFamily: 'Rubik',
      fontWeight: 500,
      textTransform: 'none',
      textOverflow: 'ellipsis',
      overflow:'hidden',
      whiteSpace:'nowrap',
      width:'280px'
    }
  }
));


const ClassroomAdminSettings = (props) => {
  const {organization, usergroup, user, computedMatch} = props;

  const [inviteEmailsOpen, setInviteEmailsOpen] = useState(false);
  const [reportingUsers, setReportingUsers] = useState([]);
  const [classroomOnlyReportingUserIds, setClassroomOnlyReportingUserIds] = useState([]);
  
  
  const [showDeleteConfirmationMessage, setShowDeleteConfirmationMessage] = useState(false);
  const [toDeleteAfterConfirmationUserIds, setToDeleteAfterConfirmationUserIds] = useState([]);
  

  useEffect(() => {
    props.dispatch(organizationActions.getOrganizationUsers(organization.selectedOrganization));
    props.dispatch(organizationActions.getInviteRolesAvailable(organization.selectedOrganization));
    props.dispatch(usergroupActions.getAllUserGroupsForOrganisation(organization.selectedOrganization));
    
  }, []);
  
  useEffect(() => {
    recalculateClassroomReportingUsers(computedMatch.params.classroomId);
    
  }, [usergroup.usergroups]);

  const recalculateClassroomReportingUsers = (userGroupId) => {
    const tempClassroom = usergroup.usergroups.find(x => x.id == userGroupId);
    const tempReportingUsers = tempClassroom?.userGroupReportingUserDtos ? tempClassroom.userGroupReportingUserDtos : [];
    let tempReportingUserIds = [];
    tempReportingUserIds.map((user, index) => {
      tempReportingUserIds.push(user.id)
      }
    )
    
    setClassroomOnlyReportingUserIds(tempReportingUserIds);
    tempReportingUsers.sort((a, b) => a.name.localeCompare(b.email))
    
    setReportingUsers(tempReportingUsers);
  }
  
  useEffect(() => {
    recalculateClassroomReportingUsers(computedMatch.params.classroomId);
  }, [organization.organizationUsers]);


  const handleAddReportingUser = (organizationId, emails, inviteRoles, userGroupId) => {
    // need to add the users first if they do not exist
    emails.map(email => props.dispatch(usergroupActions.addReportingUserToUserGroup(organization.selectedOrganization, computedMatch.params.classroomId, email)));
  }

  const handleDeleteReportingUserClicked = (userIds) => {
    
    if (userIds?.length > 0) {
      setToDeleteAfterConfirmationUserIds(userIds);
      setShowDeleteConfirmationMessage(true);
    }
  }
  
  const handleDeleteConfirmed = () => {
    setShowDeleteConfirmationMessage(false);
    toDeleteAfterConfirmationUserIds.map(userId =>  props.dispatch(usergroupActions.removeReportingUserFromUserGroup(organization.selectedOrganization, computedMatch.params.classroomId, userId)));
    setToDeleteAfterConfirmationUserIds([]);
  }
  
  const handleDeleteCanceled = () => {
    setShowDeleteConfirmationMessage(false);
    setToDeleteAfterConfirmationUserIds([]);
  }

  const isEmailOfUserSameAsAdmin = (email) => {
    return props.user.email === email;
  }
  
  
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ClassroomSidebar
        userGroupId={computedMatch.params.classroomId}
        path={computedMatch.path}
      />
      <Grid item xs={12} style={{
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'right',
        
      }}>
        <Button variant="contained"
                color={"primary"}
                style={{marginTop: '25px'}}
                onClick={() => setInviteEmailsOpen(true)}>
          <FormattedMessage id="dashboard.classroom.admins.inviteadmins" defaultMessage="Invite admins"/>
        </Button>
      </Grid>
      <Grid item xs={12} sm={12} md={12}>

        {
          showDeleteConfirmationMessage &&
          <CapeeshStyledModal
            open={showDeleteConfirmationMessage}
            textTitle={<FormattedMessage id="globalwords.deleteconfirmation" defaultMessage="Delete confirmation"/>} 
            textLine1={<FormattedMessage id="dashboard.classroom.admins.deleteconfirmation" defaultMessage="Do you really want delete these users? They will no longer be able to administer this classroom."/>}
            onButton2={() => handleDeleteConfirmed()}
            onButton1={() => handleDeleteCanceled()}
            button1Text={<FormattedMessage id="globalwords.cancel" defaultMessage="Cancel"/>} 
            button1Variant={'outlined'}
            button2Text={<FormattedMessage id="globalwords.yes" defaultMessage="Yes"/>}
            onClose={() => handleDeleteCanceled()}
          />
        }
        
        <Card>
          <CardHeader
            title={<FormattedMessage id="dashboard.classroom.admins.title"
                                     defaultMessage="Classroom Administrators"/>}
            subheader={<FormattedMessage id="dashboard.classroom.admins.subtitle1"
                                         defaultMessage="These are users that can administer this classroom. They can see this classroom, see statistics and administer the users."/>}
          />
          <CardContent>
            <EmailInvite inviteEmailsOpen={inviteEmailsOpen}
                         title={<FormattedMessage id="dashboard.classroom.admins.invitenewadminstitle" defaultMessage="Invite new Classroom Administrators"/>}
                         description={<FormattedMessage id="dashboard.classroom.admins.invitenewadminsdescription" defaultMessage="Enter emails for user to invite. Use comma to separate and add more than one."/>}
                         handleInviteEmailsClose={() => setInviteEmailsOpen(false)}
                         availableRoles={["Reporting"]}
                         callBackFunctionOnAdd={handleAddReportingUser}
            />
            <Grid container spacing={2}>
              <Grid item xs={12}>

                <TableContainer sx={{marginTop: '30px', minWidth: 400, backgroundColor: CapeeshColors.Gray5, borderRadius: '16px'}}>
                  <Table style={{marginTop: '20px', marginBottom: '20px'}}  size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell width="30%">
                          <Typography className={classes.tableHeaderCell}>
                            <FormattedMessage id="globalwords.name" defaultMessage="Name"/>
                          </Typography>
                        </TableCell>

                        <TableCell width="30%">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="globalwords.role" defaultMessage="Role"/>
                          </Typography>
                        </TableCell>
                        
                        <TableCell width="30%">
                          <Typography className={classes.tableHeaderCell}>
                            <FormattedMessage id="globalwords.email" defaultMessage="Email"/>
                          </Typography>
                        </TableCell>
                        
                        <TableCell width="10%">
                          <Typography className={classes.tableHeaderCellSmallText}>
                            <FormattedMessage id="dashboard.administrators.classroom.table.delete" defaultMessage="Delete"/>
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {reportingUsers.map((classroomAdmin) => (
                        <TableRow
                          key={classroomAdmin.id}
                          // onClick={() => handleGoToSingleClassroom(userGroup.id)}
                          sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                          <TableCell width="30%" component="th" scope="row">
                            <Typography className={classes.tableRowCellName}>
                              {classroomAdmin.name}
                            </Typography>
                          </TableCell>
                          <TableCell width="30%" align="right">
                            <Typography style={{
                              textAlign: 'left',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: 400,
                              fontFamily: 'Rubik',}}>
                              {classroomAdmin.roles}
                            </Typography>
                          </TableCell>
                          <TableCell width="30%" component="th" scope="row">
                            <Typography className={classes.tableRowCellName}>
                              {classroomAdmin.email}
                            </Typography>
                          </TableCell>
                          {
                            <TableCell width="10%" align="right">
                              <DeleteIcon style={isEmailOfUserSameAsAdmin(classroomAdmin.email) ? {opacity: 0.25} : {}}
                                          onClick={() => {
                                            if(isEmailOfUserSameAsAdmin(classroomAdmin.email)) return;
                                            handleDeleteReportingUserClicked([classroomAdmin.id]);
                                          }}/>
                            </TableCell>
                          }
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

    </div>
  );
}

function mapStateToProps(state) {
  const {organization, usergroup, user} = state;
  return {
    organization,
    usergroup,
    user,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(ClassroomAdminSettings)
